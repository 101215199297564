<template>
  <div class="header">
    <!-- 顶部 -->
    <!-- 电脑端顶部 -->
    <div v-show="!$store.state.isPhone" class="web">
      <div class="left">
        <img src="../../assets/img/header/logo.png" alt="">
      </div>
      <div class="right">
        <ul>
          <li v-for="item in menulist" :key="item.id" @mouseleave="defaultActive" @mouseover="activeHover(item.path)"
            @click="changePath(item.path)">
            <span :class="$route.path.indexOf(item.path) !== -1 ? 'activeTitle' : ''">{{ item.title }}</span>

          </li>
          <span class="active" :style="{ left: leftWidth }"></span>
        </ul>
        <div class="language">
          <img @click="changeLanguage" src="../../assets/img/header/language.png" alt="">
          <!-- <span>EN</span> -->
        </div>
      </div>
    </div>
    <!-- 电脑小分辨率顶部 -->
    <div v-show="!$store.state.isPhone" class="MinWeb">
      <div class="left">
        <img src="../../assets/img/header/logo.png" alt="">
      </div>
      <div class="center">
        <span>{{ activeName }}</span>
      </div>
      <div class="right">
        <div class="language">
          <!-- <span>EN</span> -->
          <img @click="changeLanguage" src="../../assets/img/header/language.png" alt="">
        </div>
        <i class="el-icon-s-fold" @click="openDrawer"></i>
      </div>
    </div>
    <!-- 手机顶部 -->
    <div v-show="$store.state.isPhone" class="phone">
      <div class="left">
        <img src="../../assets/img/header/logo.png" alt="">
      </div>
      <div class="center">
        <span>{{ activeName }}</span>
      </div>
      <div class="right">
        <div class="language">
          <img @click="changeLanguage" src="../../assets/img/header/language.png" alt="">
          <!-- <span>EN</span> -->
        </div>
        <i class="el-icon-s-fold" @click="openDrawer"></i>
      </div>
    </div>
    <el-drawer title="0" :visible.sync="drawer" :with-header="false" :append-to-body="true" size="40%"
      :modal-append-to-body="false">
      <ul class="drawerUl">
        <li :class="$route.path.indexOf(item.path) !== -1 ?  'active' : ''" v-for="item in menulist" :key="item.id"
          @click="changePath(item.path)">
          <span>{{ item.title }}</span>

        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
  data() {
    return {
      leftWidth: '5.5%', // 默认导航下面滑块的位置
      menulist: [
      // , list: [{id: 1, text: '高水准方案设计'}, {id:2 , text: '高效率软件交付'}, {id: 3, text: '高品质项目成果'}]
        { id: 1, title: '首页', path: '/home', text: {title: '5年匠心 专注研发', summary: '精准助力企业信息化'} },
        { id: 2, title: '解决方案', path: '/scheme', text: {title: '定制全流程解决方案', summary: '由专业项目经理提供个性化服务'}  },
        { id: 3, title: '心理测评系统', path: '/evaluation', text: {title: '软件源头供应商',  summary: '为诊断和治疗提供有力支持'}  },
        { id: 4, title: '公司动态', path: '/trends', text: {title: '解锁数字时代机遇', summary: '实现创意与创新的快速发展'}  },
        { id: 5, title: '关于我们', path: '/contact', text: {title: '专业的软件开发公司', summary: '与您携手共铸数字未来'}  },

      ], // 菜单
      drawer: false, // 侧边栏模态框
      bannerList: [],
      imgPath: ''
    }
  },
  methods: {
    // 打开侧边栏
    openDrawer() {
      this.drawer = true;
    },
    // 切换路由
    changePath(val) {
      if (val === '/home') {
        this.leftWidth = '5.5%'
      } else if (val === '/scheme') {
        this.leftWidth = '22%'
      } else if (val === '/evaluation') {
        this.leftWidth = '43.2%'
      } else if (val === '/trends') {
        this.leftWidth = '65%'
      } else if (val === '/contact') {
        this.leftWidth = '84%'
      }
      this.$router.push(val);
      this.getList();
      this.drawer = false;
    },
    // 获取附件列表
    async getList() {
      let info = { pageSize: 5, pageNum: 1, categoryId: 4 }
      if(this.$store.state.isPhone) {
        info.categoryId = 8
    }
      const res = await attachmentListAPI(info);
      console.log(res);
      if (res.code === 200) {
        this.bannerList = res.data.list;
        this.bannerList.forEach(item => {
          if (item.title.indexOf(this.activeName) !== -1 ) {
            // console.log(name)
            // console.log(item.title)
            this.imgPath = item.path;
            this.$store.commit('changeBannerInfo',  this.imgPath)
            
          }
        })
      }
    },

    // 鼠标移入时
    activeHover(val) {
      if (val === '/home') {
        this.leftWidth = '5.5%'
      } else if (val === '/scheme') {
        this.leftWidth = '22%'
      } else if (val === '/evaluation') {
        this.leftWidth = '43.2%'
      } else if (val === '/trends') {
        this.leftWidth = '65%'
      } else if (val === '/contact') {
        this.leftWidth = '84%'
      }
    },
    // 鼠标离开时，回到原来的位置
    defaultActive() {
      if (this.$route.path === '/home') {
        this.leftWidth = '5.5%'
      } else if (this.$route.path === '/scheme') {
        this.leftWidth = '22%'
      } else if (this.$route.path === '/evaluation') {
        this.leftWidth = '43.2%'
      } else if (this.$route.path.indexOf('/trends') !== -1) {
        this.leftWidth = '65%'
      } else if (this.$route.path === '/contact') {
        this.leftWidth = '84%'
      }
    },
    // 切换语言
    changeLanguage() {
      // window.location.href = 'http:192.168.0.119:8081/#/en/home'
      this.$router.push('/en/home')
      // window.location.href = 'https://zhihanyixing.com/#/en/home'
      // window.location.href = ""
    }
  },
  created() {
    // console.log(this.$route.path)
    if (this.$route.path === '/home') {
      this.leftWidth = '5.5%'
    } else if (this.$route.path === '/scheme') {
      this.leftWidth = '22%'
    } else if (this.$route.path === '/evaluation') {
      this.leftWidth = '43.2%'
    } else if (this.$route.path.indexOf('/trends') !== -1) {
      this.leftWidth = '65%'
    } else if (this.$route.path === '/contact') {
      this.leftWidth = '84%'
    }
    
    this.getList();
  },
  computed: {
    activeName() {
      let name = ''
      let path = this.$route.path;

      if(this.$route.path.indexOf('/trends') !== -1) {
        path = '/trends'
      }
      
      this.menulist.forEach(item => {
        if (item.path === path) {
          name = item.title
          this.$store.commit('changeBannerText',  item.text)
        } 
      })
      return name
    },
    // activePath() {
    //   let path = ''
    //   if(this.$route.path.indexOf('/trends') !== -1) {
    //     path = '/trends'
    //   } else {
    //     path = this.$route.path;
    //   }
    //   return path
    // }
  }
}
</script>

<style lang="scss" scoped>
@keyframes color {
  from {
    // font-size: 17px;
    color: #B0B0B0;
  }

  to {
    // font-size: 21px;
    color: var(--custom-color);
  }
}

@keyframes bottomActive {
  from {
    width: 0px;

  }

  to {
    width: 28px;
  }
}

.drawerUl {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    line-height: 55px;
    padding-left: 15px;
    border-bottom: 5px solid white;

    span {
      
      // font-size: 14px;
    }
  }

  .active {
    border-bottom: 5px solid var(--custom-color);

    span {
      color: var(--custom-color);
    }
  }
}

.header {
  background: var(--theme-color);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);



  .language {
    display: inline-block;
    img{
      height: 35px;
    }
  }

  .web {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .right {
      display: flex;
      align-items: center;

      ul {

        list-style: none;
        margin: 0px;
        // display: inline-block;
        height: 95px;
        display: flex;
        align-items: center;
        position: relative;




        li {
          padding-right: 80px;
          text-align: center;
          cursor: pointer;

          &:hover {
            span {
              animation-name: color;
              animation-duration: 0.5s;
              animation-fill-mode: both;
            }



          }

          span {
            font-size: 18px;
            color: #B0B0B0;
          }

          .activeTitle {
            // font-size: 21px;
            // font-weight: 600;
            color: var(--custom-color);
          }
        }

        .active {
          display: block;
          width: 29px;
          height: 8px;
          background: var(--custom-color);
          border-radius: 2px;
          position: absolute;
          bottom: 20%;
          transition: all 0.3s;

        }

      }


    }

    .left {
      img {
        width: 175px;
      }
    }
  }

  .MinWeb {

    display: none;
    padding: 0 20px;
    height: 96px;

    .left,
    .center,
    .right {
      width: 130px;
      // text-align: center;
    }

    .left {
      img {
        width: 125px;
      }
    }

    .center {
      text-align: center;

      span {
        font-size: 18px;
        // font-weight: 600;
        color: white;
      }
    }

    .right {
      // text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        font-size: 30px;
        color: white;
      }

      .language {
        margin-right: 15px;
    
        img{
          height: 28px;
        }
      }
    }
  }

  .phone {
    // display: none;
    padding: 0 10px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left,
    .center,
    .right {
      width: 100px;
      // text-align: center;
    }

    .left {
      img {
        width: 70px;
      }
    }

    .center {
      text-align: center;

      span {
        // font-size: 18px;
        // font-weight: 600;
        color: white;
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .language {
        padding-top: 3px;
        margin-right: 5px;
        display: block;

       img{
        height: 22px;
       }
      }

      i {
        font-size: 28px;
        color: white;
      }
    }
  }
}

@media screen and (max-width:1120px) {
  .header {
    .web {
      .left {
        img {
          width: 125px;
        }
      }

      .right {
        ul {
          li {
            padding-right: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width:900px) {
  .header {
    .web {
      display: none;
    }

    .MinWeb {
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;



    }
  }
}
</style>