<template>
  <div class="index">
    <div class="indexHead">
      <Header></Header>
    </div>
    <div>
      <Banner></Banner>
    </div>
    <div class="indexMain">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/web/Header.vue';
import Footer from '@/components/web/Footer.vue';
import Banner from '@/components/web/Banner.vue';
export default {
  components: { Header, Footer, Banner },
  created() {
    let userAgentInfo = navigator.userAgent;
    let mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

    let mobile_flag = false;

    //根据userAgent判断是否是手机
    for (let v = 0; v < mobileAgents.length; v++) {
      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        mobile_flag = true;
        break;
      }
    }
    this.$store.commit('changeIsPhone', mobile_flag)
    // console.log(mobile_flag);

  }
}
</script>

<style lang="scss">
.index {
  position: relative;

  .indexHead {
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  .WebMain {
    p {
      margin: 0;
      padding: 0;
    }

    .DivTitle {
      font-size: 30px;
      font-weight: 600;
      color: #222222;
      line-height: 42px;
      padding-top: 60px;
      padding-bottom: 40px;
      text-align: center;
    }

    // .DivContent {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;

    //   .item {
    //     .title {
    //       color: #222222;
    //     }

    //     .content {
    //       color: #666666;
    //     }
    //   }
    // }
  }

}
@media screen and (max-width:500px){
  .index{
    .WebMain{
      .DivTitle{
        font-size: 24px;
      }
    }
     
  }
}
</style>