<template>
  <div class="footer">
    <!-- 底部 -->
    <div :class="$store.state.isPhone ? 'phone WebMain' : 'WebMain web'">
      <div class="top">
        <div class="left">
          <p class="topTitle">Contact</p>
          <div class="textDiv">
            <img src="../../assets/img/footer/code.png" alt="">
            <div class="text">
              <p>Phone: 028-85772552</p>
              <p>Email: cs@zhihanyixing.com</p>
              <p>Address: Room 1503, Block C, Oriental Plaza, No. 229 East Avenue, Jinjiang District, Chengdu，China.</p>
            </div>
          </div>
        </div>
        <div class="right">
          <p class="topTitle">About us</p>
          <div class="textDiv">
            <img src="../../assets/img/footer/enlogo.png" alt="">
            <p>
              ThriverWise adheres to the business philosophy of technological innovation, customer-first, and continuous optimization. We provide innovative and cost-effective digital services to our customers, and are committed to helping enterprises embrace digitization and meet future challenges.
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div>
          <p>Copyright © 2021 All Rights Reserved. Powered by ThriverWise Network Information Technology Co., Ltd.</p>
          <p ><a style="color: #BBBBBB;" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021019104号-1</a> <a style="color: #BBBBBB;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004038" target="_blank" rel="noopener noreferrer"> <img style="height: 20px;width: 20px;margin-left: 20px;" src="../../assets/img/footer/备案图标.png" alt=""> 川公网安备 51019002004038号</a></p>
          <!-- <p>蜀ICP备2021019104号-1 川公网安备 51019002004038号</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #222;
  color: #BBBBBB;


  .web {

    .top,
    .bottom {
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // justify-content: space-between;

      p {
        font-size: 17px;
      }

    }

    .top {
      padding-bottom: 43px;
      border-bottom: 1px solid #6E6E6E;

      .left,
      .right {
        width: 560px;
        padding-top: 30px;

        .topTitle {
          font-size: 20px;
          font-weight: 600;
          color: #FFFFFF;
        }

        .textDiv {
          width: 100%;
          border-top: 1px solid #6E6E6E;
          margin-top: 24px;
          padding-top: 26px;
          display: flex;

          .text {
            p {
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0px;
                line-height: 27px;
              }
            }
          }
        }
      }

      .right {
        margin-left: 60px;

        p {
          line-height: 27px;
        }

        img {
          // height: 40px;
          width: 110px;
          height: 110px;
          margin-right: 30px;
        }
      }

      .left {
        img {
          height: 130px;
          width: 130px;
          margin-right: 30px;
        }
      }


    }

    .bottom {
      padding-bottom: 30px;
      padding-top: 24px;
      justify-content: space-between;

      // padding-bottom: 26px;
      img {
        width: 175px;
      }

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

  }

  .phone {

    .top,
    .bottom {
      // // width: 100%;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: center;
      // justify-content: space-between;

      p {
        font-size: 13px;
      }

    }

    .top {
      padding-bottom: 24px;
      border-bottom: 1px solid #6E6E6E;

      .left,
      .right {
        // width: 560px;
        padding-top: 20px;

        .topTitle {
          // font-size: 20px;
          font-weight: 600;
          color: #FFFFFF;
        }

        .textDiv {
          width: 100%;
          border-top: 1px solid #6E6E6E;
          margin-top: 10px;
          padding-top:10px;
          display: flex;

          .text {
            p {
              margin-bottom: 8px;

              &:last-child {
                margin-bottom: 0px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .right {
        // margin-top: 5px;
        // margin-left: 60px;

        p {
          line-height: 20px;
        }

        img {
          // height: 40px;
          width: 80px;
          height: 80px;
          // height: 28px;
          margin-right: 30px;
        }
      }

      .left {
        img {
          height: 90px;
          width: 90px;
          margin-right: 30px;
        }
      }


    }

    .bottom {
      padding-bottom: 30px;
      padding-top: 6px;
      justify-content: space-between;

      // padding-bottom: 26px;
      img {
        width: 175px;
      }

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

  }

}

@media screen and (max-width:1180px) {
  .footer .WebMain .top .right {
    margin-left: 0px;
  }
}</style>