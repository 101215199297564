<template>
    <div class="Banner">
        <!-- banner区域 -->
        <!-- {{ $store.state.requestEnglishAPI + $store.state.bannerInfoEnglish }} -->

        <img :src="$store.state.requestEnglishAPI + $store.state.bannerInfoEnglish.imgPath" alt="">
        <div :class=" $store.state.isPhone? 'phone text': 'text'">
            <div class="WebMain">
                <p class="title p58">{{ $store.state.bannerInfoEnglish.text.title }}</p>
                <p class="content p32">{{ $store.state.bannerInfoEnglish.text.summary }}</p>
                <p class="list p26" v-if="$store.state.bannerInfoEnglish.text.list">
                    <span v-for="item in $store.state.bannerInfoEnglish.text.list" :key="item.id"> {{ item.text }}</span>
                </p>
            </div>
        </div>

    </div>
</template>

<script>
// import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
    data() {
        return {
        }
    },
    methods: {
    },
    created() {
    },

}
</script>

<style lang="scss" scoped>
.Banner {
    // width: 100%;
    // overflow: hidden;
    height: 450px;
    position: relative;

    img {
        width: 100%;
        height: 450px;

    }

    .text {
        position: absolute;
        top: 0;
        width: 100%;

        .WebMain {
            // height: 100%;
            margin: auto;
            // text-align: center;
            padding-top: 90px;

            .title {
                margin-bottom: 20px;
                font-weight: 600;
            }
            .list{
                margin-top: 35px;

                span{
                    margin-left: 15px;
                    font-weight: 600;
                }
            }
        }

        p {
            color: white;
        }
    }
    .phone.text{
        bottom: 0;
    }
}
</style>