<template>
  <div class="home">
    <!-- 首页 -->
    <el-container>
      <el-aside width="none">

        <AsideMenu></AsideMenu>
      </el-aside>
      <el-container>
        <el-header style="height: 50px;">
          <Header></Header>
        </el-header>
        <el-main>
          <!-- <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="item in $route.meta.breadcrumbList" :key="item.id">{{ item.name }}
              <el-tooltip :content="$route.meta.info" placement="bottom" effect="light">
                <i v-show="item.id === ($route.meta.breadcrumbList).length && $route.meta.info" class="el-icon-question"></i>
              </el-tooltip>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div> -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <!-- <Editor></Editor> -->
  </div>
</template>

<script>
import Editor from '@/components/Editor.vue';
import AsideMenu from '@/components/AsideMenu.vue';
import Header from '@/components/Header.vue';
export default {
  name: 'Home',
  components: {
    Editor, AsideMenu, Header
  },
  data() {
    return {
      // isCollapse:false
    }
  },

  created() {
    
  }

}
</script>
<style scoped lang="scss">
.home {

  // height: 100vh;
  // width: 100vw;
  .el-header,
  .el-footer {
    background-color: var(--customManagement-color);
    // color: #333;
    // text-align: center;
    line-height: 50px;
  }

  .el-aside {
    height: 100%;
    
    // background-color: #D3DCE6;
    background-color: var(--customManagement-color);
    color: var(--white-color);
    // text-align: center;
    // line-height: 200px;
  }

  .el-main {
    height: calc(100vh - 50px);
    padding: 10px;
    box-sizing: border-box;
    // background-color: #E9EEF3;
    // color: #333;
    // text-align: center;
    // line-height: 160px;
    .breadcrumb{
      margin-bottom: 20px;
    }
  }

  body>.el-container {
    margin-bottom: 40px;
  }

  .el-container .el-aside {
    height: 100vh;
    // line-height: 260px;
  }

}
</style>
